.App {
  font-family: sans-serif;
  margin: 0;
  padding: 0;
  height: 100%;
}

header {
  background-color: #4285f4;
  color: white;
  padding: 10px;
}

header h1 {
  margin: 0;
}

@keyframes movingGradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.background-animation {
  background: linear-gradient(270deg, rgba(30, 144, 255, 1), rgba(255, 165, 0, 1), rgba(214, 112, 240, 1));
  background-size: 300% 300%;
  animation: movingGradient 30s ease infinite;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}