.exams-selection {
    margin-left: 20px;
  }
  
  .exam-list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    /* This will center each card horizontally */
    gap: 20px;
    width: 100%;
  }
  
  .exam-card {
    cursor: pointer;
    background-color: white;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    border-radius: 5px;
    width: 1200px;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
  }
  
  .exam-card-content {
    flex-grow: 1;
    display: flex;
    align-items: center;
    padding: 0 15px;
  }
  
  .due-date {
    border-right: 1px solid #000;
    padding-right: 15px;
    margin-right: 15px;
    text-align: center;
    font-weight: 600;
  }
  
  .chevron {
    color: blue;
  }
  
  
  .exam-card img {
    width: 100%;
    height: 150px;
    object-fit: cover;
  }
  
  .title {
    font-size: 2rem;
  }
  
  .card {
    font-size: 2rem;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    gap: 10px;
  }
  
  .card img {
    width: 50px;
    /* adjust as needed */
    height: 50px;
    /* adjust as needed */
  }
  
  .tab-buttons {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .tab-button {
    background: none;
    border: none;
    padding: 10px 20px;
    margin: 0 10px;
    cursor: pointer;
    transition: color 0.3s;
    position: relative;
    color: black;
    font-weight: bold;
    text-transform: uppercase;
  }
  
  .tab-button:hover {
    color: #3171d8;
  }
  
  .tab-button::after {
    content: "";
    display: block;
    height: 2px;
    background: blue;
    width: 0;
    transition: width 0.3s ease-in-out;
    position: absolute;
    bottom: 0;
    left: 0;
  }
  
  .tab-button.active::after {
    width: 100%;
  }