.progress-bar-container {
  position: relative;
  background-color: #eee;
  width: 10px;
  height: 300px; /* You can adjust the height to your preference */
  margin: 20px;
  border-radius: 15px;
}

.progress-bar {
  position: absolute;
  left: 0;
  bottom: -30px;
  right: 0;
  background-color: #00a1ff;
  border-radius: 15px;
  transition: height 0.5s ease-in-out;
}

.milestones {
  position: absolute;
  left: 10px;
  top: -15px;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.milestone {
  position: relative;
}

.completed-checkpoint,
.pending-checkpoint {
  position: absolute;
  font-size: 12px;
  white-space: nowrap;
  left: 22px;
  transform: translateY(50%);
}

.completed-checkpoint {
  color: #00a1ff;
}

.pending-checkpoint {
  color: #777;
}

.checkpoint-text {
  font-size: 14px;
  font-weight: bold;
}
