.account-container {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: flex-start;
}

.account-card {
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  flex: 1 1 300px;
  margin: 1rem 0;
  max-width: 300px;
  padding: 1.5rem;
  position: relative;
  text-align: center;
  transition: transform 0.3s;
  word-wrap: break-word; /* Add this to fix the text overflow */
}

.account-content-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 330px;
}

.account-card:hover {
  transform: translateY(-10px);
}

.account-content {
  margin-top: 0rem;
  font-size: 0.9rem;
}

.edit-btn {
  line-height: 15px;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  color: #ffffff;
  cursor: pointer;
  font-size: 0.9rem;
  font-weight: bold;
  padding: 0.5rem 1rem;
  text-transform: uppercase;
  transition: background-color 0.3s;
  height: 30px;
}

.edit-btn:hover {
  background-color: #0056b3;
}

.account-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px 0;
}

.account-form label {
  font-weight: bold;
}

.account-form input {
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 0.9rem;
}

.account-form button[type='submit'] {
  padding: 6px 12px;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  color: white;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
  transition: background-color 0.3s;
  height: 30px
}

.account-form button[type='submit']:hover {
  background-color: #0056b3;
}

.form-container {
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  transition: max-height 1s cubic-bezier(0.4, 0, 0.2, 1) 0s, opacity 1s cubic-bezier(0.4, 0, 0.2, 1) 0s;
}

.form-container.show {
  max-height: 300px;
  opacity: 1;
  transition: max-height 1s cubic-bezier(0.4, 0, 0.2, 1) 0s, opacity 1s cubic-bezier(0.4, 0, 0.2, 1) 0s;
}