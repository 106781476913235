.grade-item-li {
    padding: 10px;
    height: 65px;
    background-color: #ffffff;
    border-bottom: 1px solid #ddd;
    list-style: none;
    flex-direction: column; /* if you want the name and score stacked */
}

.last-item {
    border-bottom-left-radius: 10px;
}

.grade-item-name {
    font-size: 1.2em;
    font-weight: bold;
    text-align: left;
    margin-left: 13px;
}

.grade-item-score {
    font-size: 1em;
    color: darkgreen;
    margin-left: 13px;
}

.grade-card {
    /* styling for the card */
    flex: 2.75;
    /* Adjust the height as per your needs */
    flex-direction: column;
    /* Add some padding so the bars don't touch the edges */
    /* styling for the card */
    border: 1px solid #ddd;
    background-color: rgb(247, 236, 247);
    border-radius: 10px;
    box-shadow: 2px 2px 8px rgba(0,0,0,0.2);
    padding: 10px;
    padding-top: 10px;
    padding-right: 50px;
    margin-top: 20px;
    margin-right: 20px;
    width: 73%;
    height: 600px;
    display: flex; /* added this to make the list items align horizontally */
}

.grades-content {
    border-radius: 10px;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #888 #f1f1f1;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    flex-grow: 1;
    padding-right: 10px;
}


.grades-content::-webkit-scrollbar {
    width: 10px;
}

.grades-content::-webkit-scrollbar-track {
    background-color: #dcdbdb;
    border-radius: 10px;
}

.grades-content::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}

.grade-button {
    color: white;
    background-color: purple;
    border: none;
    border-radius: 50px;
    padding: 5px 10px;
    margin: 5px;
    cursor: pointer;
    transition: background-color 0.2s ease;
    font-size: 0.8rem;
    width: 100px;
    height: auto;
}

.grade-button:hover {
    background-color: darkorange;
}

.grade-button-active {
    background-color: darkorange;
    /* or whatever color you want when the button is active */
}

.button-container {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: nowrap;
    width: 100%;
    height: 40px;
    margin-bottom: 10px;
}


.cards-container {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
}

.container {
    display: flex;
    justify-content: space-between;
    width: 90%;
    /* This makes the total width of both cards to be 70% of the page width */
}

.card-title {
    margin-left: 20px;
    /* Add the desired margin */
    /* Include other styles as necessary */
}

.row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    /* Change this to adjust the space between the cards */
    width: 70%;
    /* This makes the total width of both cards to be 70% of the page width */
}

.opening-card {
    display: flex;
    flex-direction: row;
    /* Change from column to row */
    justify-content: space-between;
    background-color: #3d3d3d;
    border-radius: 10px;
    height: 150px;
    /* Adjust the height as per your needs */
    width: 90%;
    /* Adjust the width as per your needs */
    /* remaining styles... */
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.15);
}

.opening-header {
    /* properties for "Welcome User!" text */
    font-size: 2em;
    /* adjust as per your needs */
    color: #ffffff;
    margin-left: 50px;
    /* adjust as per your needs */
}

.opening-description {
    /* properties for the description text */
    font-size: 1em;
    /* adjust as per your needs */
    color: #fdfcfc;
    margin-left: 50px;
    /* adjust as per your needs */
}

.opening-card img {
    /* Set the size of the image */
    width: 120%;
    height: 100%;
    object-fit: cover;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    /* Set the clip-path */
    clip-path: polygon(20% 0, 100% 0, 100% 100%, 0 100%);
}

.opening-card-content {
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    width: 100%;
    flex-grow: 1;
    text-align: left;
}

.opening-card-content h3 {
    font-size: clamp(1rem, 2.5vw, 1.2rem);
    /* Adjust these values according to your design */
    display: flex;
    overflow: hidden;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 95%;
    /* Add a maximum width */
}

.courses-title {
    margin-left: 0px;
}

.courses-content {
    border-radius: 10px;
    overflow-y: auto;
    flex-grow: 1;
    padding-right: 10px;
    list-style: none;
    /* removes the default bullet points */
    padding: 0;
    border: 1px solid #ddd;
    /* add a border */
}

.course-name {
    font-size: 30px;
    margin: 10px;
    color: black;
    /* Styles for the course name, adjust as needed */
}

.card-title {
    font-size: x-large;
    margin-bottom: 60px;
}

.grades-card {
    flex: 1;
    background-color: rgb(241, 241, 241);
    border-radius: 15px;
    margin-top: 20px;
    height: 400px;
    /* Adjust the height as per your needs */
    display: flex-start;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
    /* Add some padding so the bars don't touch the edges */
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.15);
    /* ... */
}

.grade-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    margin-left: 20px;
}

.grade-label {
    display: flex;
    margin-right: 20px;
    font-size: 2.4em;
    /* Adjust as needed */
    font-family: monospace;
}

.total-scores {
    display: flex;
    flex-direction: column;
    align-items: left;
    padding: 10px;
    width: fit-content;
    margin: auto;
    margin-top: 15px;
}

.score-text {
    text-align: left;
    width: fit-content;
}

.total-scores p {
    margin: 0;
    padding: 5px 0;
}