/* ModalContent.module.css */
.modal-content-card {
    background-color: white;
    border: 1px solid #ccc;
    padding: 16px;
    margin: 8px;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  .modal-content-card h4 {
    margin: 0;
    padding-bottom: 8px;
    border-bottom: 1px solid #ccc;
  }

  .loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .submit-button {
    margin-top: 10px;
    width: 150px;
    height: 35px;
    background-color: #4285f4;
    color: white; 
    text-align: center;
    font-size: 16px;
    height: 30px;
    border-radius: 20px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    border: none;  
}
.submit-button:hover {
  background-color: #357ae8;
}

  