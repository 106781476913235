.studentDashboard {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
}

.statisticsComponent {
  max-width: 60%;
}

.students-list-container {
  max-width: 25%;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.students-list {
  width: 100%;
  overflow-y: scroll;
}

.students {
  list-style: none;
  padding: 0;
  margin: 0;
}

.students li {
  margin: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.students li h2 {
  margin: 0;
  font-size: 20px;
}

.students li p {
  margin: 5px 0;
}

