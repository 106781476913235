.assignment-details {
    padding-bottom: 1rem; /* adjust as needed */
}
.block {
    margin-top: 2.5rem; /* adjust as needed */
}

.submit-button {
    background-color: lightblue; 
    color: #000; 
    padding: 15px 30px; /* Increase padding to make button bigger */
    border: none;
    cursor: pointer; 
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px; /* Increase font size to make button text bigger */
    margin: 8px 4px;
    border-radius: 5px; /* Rounded corners */
    position: fixed; 
    bottom: 20px; 
    right: 20px; 
    transition-duration: 0.4s; /* Transition effect */
}
.submit-button:hover {
    background-color: #008cba; /* Darker blue color on hover */
    color: white; 
}

.save-button {
    background-color: lightblue; 
    color: #000; 
    padding: 15px 30px; /* Increase padding to make button bigger */
    border: none;
    cursor: pointer; 
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px; /* Increase font size to make button text bigger */
    margin: 8px 4px;
    border-radius: 5px; /* Rounded corners */
    position: fixed; 
    bottom: 20px; 
    right: 150px; 
    transition-duration: 0.4s; /* Transition effect */
}
.save-button:hover {
    background-color: #008cba; /* Darker blue color on hover */
    color: white; 
}