html, body, #root, .wrapper {
  height: 100%;
}

.wrapper {
  display: flex;
}

.sidebar {
  background-color: #3d3d3d;
  color: white;
  width: 200px;
  padding: 20px;
  height: 100%;
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 65px;
}

.sidebar-nav {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  padding: 20px 0;
  width: 100%;
}

.sidebar ul {
  list-style: none;
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.sidebar li {
  cursor: pointer;
  padding: 10px;
  border-radius: 5px;
  transition: background-color 0.3s;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 18px;
  margin-bottom: 10px;
  width: 100%;
  box-sizing: border-box;
}

.sidebar li a {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
}

.sidebar li:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.sidebar span {
  margin-left: 10px;
}

.nav-selection {
  display: flex;
  flex-direction: column;
  margin: 20px 0;
  margin-left: 220px;
  margin-top: 65px;
  width: calc(100% - 220px);
}

.selected-link {
  color: #6ba0f7;
}

.redDot {
  height: 10px;
  width: 10px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
  margin-left: 10px;
}

