.chat-box {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  height: auto; /* Adjust this value */
  max-height: 500px; /* Adjust this value */
  overflow-y: auto; /* Adds a scrollbar when necessary */
  margin: 0 auto;
  padding: 15px;
  width: 800px; 
  background-color: #f8f9fa;
  border-radius: 10px;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.4);
}

.chat-box textarea {
  width: 100%;
  min-height: 20px; /* Controls the initial height */
  max-height: 400px; /* Controls the maximum height */
  resize: none; /* Disables manual resizing */
  border: 1px solid #ced4da;
  border-radius: 5px;
  padding: 5px 10px;
  margin-top: 5px;
  font-size: 16px;
}

.chat-box button {
  width: 100px;
  height: 35px;
  margin-top: 5px;
  margin-left: 10px; 
  background-color: #4285f4;
  color: white;
  font-size: 16px;
  font-weight: 500;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.chat-box button:hover {
  background-color: #357ae8; 
}

.chat-box form {
  display: flex;
  width: 100%;
}

.chat-box textarea:focus {
  outline-color: #6199f5; /* change to desired color */
}

.error-message {
  color: red;
  font-weight: bold;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid red;
  border-radius: 4px;
  background-color: #ffe6e6;
  text-align: center;
}


/*QUIZ MODE*/
.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.overlay {
  height:50px;
  width:100px;
  position: absolute;
  bottom: 97px;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  z-index: 10;
}

.overlay-text {
  font-size: 18px;
  font-weight: bold;
}

/*SUGGESTIONS*/
.suggestion-container {
  display: flex;
  position: absolute; /* Position it absolutely */
  justify-content: center;
  margin-bottom: 100px;
}

.suggestion-button {
  background-color: white !important;
  border: 1px solid grey !important;
  color: #6199f5 !important;
  text-align: center;
  font-size: 14px;
  height: 30px !important;
  border-radius: 20px !important;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s !important;; 
}

.suggestion-button:hover {
  background-color: #6199f5 !important;;
  color: white !important;;
}
