.course-page {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .content {
    display: flex;
    width: 100%;
    margin-top: 45px;
  }
  
  .center-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;
    padding: 0 20px;
  }

  /* LessonInformation.module.css */
.lesson-info-container {
  padding: 20px;  /* Adjust as needed */
}

.lesson-chat-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
