/* CourseCreationModal.module.css */

.formControl {
    min-width: 120px;
    max-width: 220px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .inputLabel {
    margin-right: 10px;
  }
  