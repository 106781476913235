.stripe-card-element-container {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .stripe-card-element-container .StripeElement {
    display: block;
    margin: 10px 0;
    padding: 10px 14px;
    font-size: 16px;
    line-height: 24px;
    width: 100%;
    border: 1px solid #ccc;
    background-color: white;
    border-radius: 4px;
  }

.billing-form-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.course-selection h2 {
  margin-left: 0;
}

.course-selection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.billing-content {
  width: 100%;
}
