.image-content-card {
    max-width: 400px;
    max-height: 400px;
    overflow: hidden;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.4);
    transition: 0.3s;
    border-radius: 15px;
    padding: 10px;
    margin: 10px;
    flex-direction: column;
    align-items: center;
    display: block;
    margin-left: auto;
    margin-right: auto;
    background-color: #ffffff;
  }
  
  .image-content-card:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.4);
  }
  
  .image-content-card img {
    width: 100%;
    border-radius: 5px 5px 0 0;
    object-fit: contain;
  }
  
  .image-content-card-description {
    margin: 15px 0;
    text-align: center;
    word-wrap: break-word; /* This property will break the words to the next line if it exceeds the width of the parent container */
    max-width: 95%;
  }