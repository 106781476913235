.question-header {
    font-weight: bold;
    font-size: 1.2rem;
}

.question-card {
    font-size: 1.4rem;
    border: 2px solid #99c7f5;
    margin-bottom: 1rem;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
}

.option {
    margin-bottom: 1rem;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
}

.option-selected {
    background-color: #b7b7b7;
    /* Adjust this color as necessary */
}

.card-container {
    max-width: 650px;
    /* 3/5 of the screen */
    margin-left: 0%;
    /* Adjust this value as necessary */
}

.markdown-content>*:last-child {
    margin-bottom: 0;
}