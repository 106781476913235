.lesson-information {
    flex-grow: 1;
    width: 800px;
    height: 700px; /* Adjust this value to your desired maximum height */
    overflow: auto;
    padding: 20px;
    padding-bottom: 40px;
    background-color: #ffffff;
    border-radius: 5px;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    margin-bottom: 20px;
  }

  @keyframes cursor-blink {
    0% { opacity: 1; }
    50% { opacity: 0; }
    100% { opacity: 1; }
  }
  
  .chat-cursor {
    display: inline-block; /* add this line */
    height: 1em;
    width: 0.1em;
    background-color: #000;
    animation: cursor-blink 1s infinite;
  }
  
  .message-container {
    display: flex;
    justify-content: flex-end;
}

.user-message {
    background-color: #007aff;
    color: #fff;
    font-weight: normal;
    padding: 8px 12px;
    border-radius: 16px;
    margin-bottom: 4px;
    max-width: 80%;
    word-wrap: break-word;
}

  .question-box {
  border: 2px solid #333;
  padding: 10px;
  border-radius: 5px;
  display: inline-block;
  margin: 5px 0;
}

.code-block-container {
  text-align: center;
}

.code-block {
  display: inline-block;
  text-align: left;
}

.mcq-container {
  font-weight: bold;
  background-color: rgba(251, 251, 114, 0.513); /* Adjust the color and opacity as needed */
  display: block;
  padding: 10px;
  margin: 10px 0;
  border-radius: 5px;
  white-space: pre-wrap;
}

.plot-container {
  margin-bottom: 10px;
}

.modal {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.8);
}

.modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 90%;
  max-height: 90%;
}

.close {
  position: absolute;
  top: 20px;
  right: 30px;
  color: #fff;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.lesson-information::-webkit-scrollbar {
  width: 10px;
  opacity: 0;
  transition: opacity 0.3s;
}

/* Handle */
.lesson-information::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */
.lesson-information::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Show scrollbar on hover */
.lesson-information:hover::-webkit-scrollbar {
  opacity: 1;
}