.progress-circle {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px;
    width: 150px;
    border-radius: 50%;
    margin: 0 auto;
}

.progress-text {
    font-size: 24px;  /* Change the size as per your requirement */
    fill: #000;      /* Change the color as per your requirement */
}
