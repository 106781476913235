.navbar {
    background-color: #4285f4;
    color: white;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    width: 100%;
    z-index: 1000;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  }
  
  /* Add any additional styles for navigation links here */
  nav ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
  }
  
  nav li {
    margin-right: 20px;
  }
  
  nav li:last-child {
    margin-right: 0;
  }

  nav a {
    color: white;
    text-decoration: none;
    font-size: 18px;
    font-weight: 500;
    transition: color 0.3s;
  }
  
  nav a:hover {
    color: #d1e1ff;
  }
  
  nav button {
    background-color: transparent;
    color: white;
    font-size: 18px;
    font-weight: 500;
    border: none;
    cursor: pointer;
    transition: color 0.3s;
  }
  
  nav button:hover {
    color: #d1e1ff;
  }

  .logo {
    font-family: 'Arial', sans-serif;
    font-size: 2em;
    font-weight: bold;
    margin-left: 25px;
  }
  
  .logo-accent {
    color: #f7c244; /* Add a custom accent color */
  }

  .logoContainer {
    display: flex;
    align-items: center;
  }
  
  .alphaLabel {
    font-size: 10px; /* adjust this to your preference */
    margin-left: 5px; /* adjust this to your preference */
    margin-top: 6px;
  }