.course-list {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 20px;
    width: 100%;
  }
  
  .course-card {
    cursor: pointer;
    background-color: white;
    border-radius: 15px;
    width: 300px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}

.course-subtitle {
  margin-top: -10px; /* remove top margin */
  font-size: 12px;
}
  
.course-card img {
    width: 100%;
    height: 150px;
    border-radius: 15px;
    object-fit: cover;
}
  
.course-card-content {
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    width: 100%;
    flex-grow: 1;
    text-align: left;
}
  
.course-card-content h3 {
    font-size: clamp(1rem, 2.5vw, 1.2rem); /* Adjust these values according to your design */
    display: flex;
    overflow: hidden;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 95%; /* Add a maximum width */
}

.course-card:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.4);
}

.long-title span {
  animation: scroll 20s linear infinite;
}

@keyframes scroll {
  0%, 40% {
    transform: translateX(0);
  }
  70%, 80% {
    transform: translateX(-100%);
  }
  90%, 100% {
    transform: translateX(0);
  }
}

.more-options-btn {
  border:none;
  background-color:transparent;
  outline:none;
  transition: all 0.3s ease;
}

.more-options-btn:hover {
  background-color: #f0f0f0;
  box-shadow: 0px 2px 4px rgba(0,0,0,0.1);
}

.new-course-title {
  text-align: center;
  margin-left: 80px;
  font-size: 2em; /* adjust size as needed */
}

.not-completed-tag {
  color: red;
  margin-left: 10px; /* adjust as needed */
}

/* TAGS */
.tag-container {
  margin-right: 5px;
  padding: 0px 5px; /* top/bottom padding of 0px, left/right padding of 5px */
  border-radius: 50px; /* makes the container pill-shaped */
}

.tag-text {
  color: white;
  font-size: 10px;
  line-height: 30px;
}