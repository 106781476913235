.cards-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-left: 15px;
}

.container {
    display: flex;
    justify-content: space-between;
    width: 70%;
    /* This makes the total width of both cards to be 70% of the page width */
}

.card-title {
    margin-left: 20px;
    /* Add the desired margin */
    /* Include other styles as necessary */
}

.row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    /* Change this to adjust the space between the cards */
    width: 70%;
    /* This makes the total width of both cards to be 70% of the page width */
}

.opening-card {
    display: flex;
    flex-direction: row;
    /* Change from column to row */
    justify-content: space-between;
    background-color: rgb(254, 224, 216);
    border-radius: 10px;
    margin-top: 25px;
    height: 200px;
    /* Adjust the height as per your needs */
    width: 70%;
    /* Adjust the width as per your needs */
    /* remaining styles... */
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.15);

}

.opening-header {
    /* properties for "Welcome User!" text */
    font-size: 2em;
    font-weight: bold;
    /* adjust as per your needs */
    color: #e05b5b;
    margin-left: 50px;
    /* adjust as per your needs */
}

.opening-description {
    /* properties for the description text */
    font-size: 1em;
    /* adjust as per your needs */
    color: #666666;
    margin-left: 50px;
    /* adjust as per your needs */
}

.opening-card img {
    /* Set the size of the image */
    width: 40%;
    /* adjust the width as per your needs */
    height: 100%;
    margin-right: 50px;
    border-radius: 0 5px 5px 0;
    object-fit: cover;
}

.opening-card-content {
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    width: 100%;
    flex-grow: 1;
    text-align: left;
}

.opening-card-content h3 {
    font-size: clamp(1rem, 2.5vw, 1.2rem);
    /* Adjust these values according to your design */
    display: flex;
    overflow: hidden;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 95%;
    /* Add a maximum width */
}

.courses-card {
    flex: 1.5;
    background: linear-gradient(270deg, rgba(214, 112, 240, 0.1), rgba(30, 144, 255, 0.1));
    border-radius: 5px;
    margin-top: 40px;
    margin-right: 40px;
    height: 520px;
    /* Adjust the height as per your needs */
    display: flex;
    flex-direction: column;
    padding: 10px;
    /* Add some padding so the bars don't touch the edges */
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.15);
}

.courses-title {
    margin-left: 0px;
}

.courses-content {
    border-radius: 10px;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #888 #f1f1f1;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    flex-grow: 1;
    padding-right: 10px;
}

.courses-content::-webkit-scrollbar {
    width: 10px;
}

.courses-content::-webkit-scrollbar-track {
    background-color: #dcdbdb;
    border-radius: 10px;
}

.courses-content::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}

.course-card {
    display: flex;
    height: 65px;
    justify-content: space-between;
    align-items: center;
    margin-top: 5px;
    margin-bottom: 10px;
    padding: 5px;
    transition: transform 0.3s ease;
    /* Change as per your needs */
    border-radius: 10px;
    /* Adjust as needed */
    position: relative;
    min-height: 65px;
}

.course-card:hover {
    transform: scale(1.01);
    cursor: pointer;
}

.course-name {
    font-size: 25px;
    margin: 10px;
    color: black;
    /* Styles for the course name, adjust as needed */
}

.course-img {
    /* Styles for the course image, adjust as needed */
    width: 12%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    right: 0;
    top: 0;
    border-radius: 0 5px 5px 0;
}

.grades-card {
    flex: 1;
    background-color: rgb(255, 255, 255);
    border-radius: 15px;
    margin-top: 40px;
    height: 450px;
    /* Adjust the height as per your needs */
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 10px;
    /* Add some padding so the bars don't touch the edges */
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.15);
    /* ... */
}

.grade-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    margin-left: 20px;
}

.grade-label {
    display: flex;
    margin-right: 20px;
    font-size: 2.4em;
    /* Adjust as needed */
    font-family: monospace;
}

.grade-A {
    color: #1a73e8;
}

.grade-B {
    color: #1a73e8;
}

.grade-C {
    color: #1a73e8;
}

.grade-D {
    color: #1a73e8;
}

.grade-F {
    color: #1a73e8;
}

.grades-bar {
    /* ...other styles... */
    background-color: lightgray;
    /* Default color */
    height: 10px;
    /* Adjust as needed */
    border-radius: 10px;
    /* Adjust as needed */
}

.bar-A {
    background: linear-gradient(to right, #1a73e8, #c760e3);
}


.bar-B {
    background: linear-gradient(to right, #c760e3, #f6bb18);
}

.bar-C {
    background-color: #1a73e8;
}

.bar-D {
    background-color: #1a73e8;
}

.bar-F {
    background: linear-gradient(to right, #f6bb18, #f66d18);
}

.grade-bar-container {
    background-color: #e1f5fe;
    /* Adjust this as per your needs */
    height: 10px;
    border-radius: 10px;
    flex-grow: 1;
}

.long-title span {
    animation: scroll 20s linear infinite;
}

@keyframes scroll {

    0%,
    40% {
        transform: translateX(0);
    }

    70%,
    80% {
        transform: translateX(-100%);
    }

    90%,
    100% {
        transform: translateX(0);
    }
}

.new-course-title {
    text-align: center;
    margin-left: 80px;
    font-size: 2em;
    /* adjust size as needed */
}