  .lesson-list-content {
    height: 610px; /* adjust to desired height */
    max-width: 600px;
    overflow-y: auto; /* enable vertical scrolling */
    padding-right: 0.5em; /* give some space for scrollbar */
  }

  .lesson-list li {
    cursor: pointer;
    padding: 5px;
    border-radius: 5px;
    min-height: 80px;
    transition: background-color 0.3s;
  }

  .lesson-item {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    padding: 10px;
    cursor: pointer;
    background-color: #f8f9fa;
    margin-bottom: 8px;
    border-radius: 4px;
    list-style: none;
    display: flex;
  }
  
  .lesson-number {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-basis: 30%; /* Or whatever width you prefer */
    height: 100%;
  }  
  
  .divider {
    width: 1px;
    height: 100%; /* Adjust to desired height */
    background-color: #000; /* Or color of your choice */
  }
  
  .lesson-title {
    flex-basis: 70%; /* Adjust width to preference */
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 10px;
  }

  .lesson-info {
    flex: 3;
    padding: 10px;
  }
  
  .lesson-list li:hover {
    background-color: #a8c5d482;
  }

  .lesson-item:not(.selected) {
    height: 60px; 
    overflow: hidden;
  }
  
  .lesson-item.selected {
    flex-direction: column;
  }

  .selected {
    background-color: #74bae082; /* You can change this color to match your design */
  }

  .lesson-header {
    padding: 10px; /* Apply the text-align property only to the header */
    margin-left: 25px;
  }

  .lesson-list-content::-webkit-scrollbar {
    width: 10px;
    opacity: 0;
    transition: opacity 0.3s;
  }
  
  /* Handle */
  .lesson-list-content::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }
  
  /* Handle on hover */
  .lesson-list-content::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  
  /* Show scrollbar on hover */
  .lesson-list-content:hover::-webkit-scrollbar {
    opacity: 1;
  }