.name-field {
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  padding-top: 0;
  transition: max-height 600ms ease-in-out, padding-top 600ms ease-in-out, opacity 600ms ease-in-out;
}

.name-field.visible {
  max-height: 100px;
  opacity: 1;
  padding-top: 20px; /* Adjust this value as needed to create space for the "Name" label */
}
