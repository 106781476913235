.statistics {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
  }

  .statistics h2 {
    text-align: left;
  }

  .average-grade-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f2f2f2;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 10px;
  }
  